import React from "react";

function ServiceComponent() {
  return (
    <div>
      <div class="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center pb-5">
        <div class="w-full bg-white border-t border-b border-gray-200 px-5 py-5 md:py-10 text-gray-800">
          <div class="w-full max-w-6xl mx-auto">
            <div class="text-center max-w-xl mx-auto">
              <h1 class="text-6xl md:text-7xl font-bold mb-5 text-gray-600">
                What our team <br />
                has to say !!
              </h1>
              <div class="text-center mb-5">
                <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
                <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
              </div>
            </div>

            <section class="text-gray-600 body-font">
              <div class="container p-5 mx-auto">
                <div class="flex flex-wrap">
                  <div class="p-2 lg:p-5 md:w-1/3">
                    <div class="grid place-items-center bg-white border border-gray-200 p-3 rounded-lg overflow-hidden text-center relative">
                      <div class="overflow-hidden object-center rounded-full w-40 h-40">
                        <img alt="content" src="./assets/President_Surjit.jpg" />
                      </div>
                      <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mt-2">
                        Dr. Surjit Singh
                      </h1>
                      <h3 class="text-lg font-medium text-gray-500 mb-3">
                        President
                      </h3>
                      <p class="leading-relaxed mb-3">
                        Be grateful and responsible while you pursue your goals.
                      </p>
                    </div>
                  </div>

                  <div class="p-2 lg:p-5 md:w-1/3">
                    <div class="grid place-items-center bg-white border border-gray-200 p-3 rounded-lg overflow-hidden text-center relative">
                      <div class="overflow-hidden object-center rounded-full w-40 h-40">
                        <img alt="content" src="./assets/Secretary_Hema.jpg" />
                      </div>
                      <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mt-2">
                        Hema Pandey
                      </h1>
                      <h3 class="text-lg font-medium text-gray-500 mb-3">
                        Secretary
                      </h3>
                      <p class="leading-relaxed mb-3">
                        Responsibility without power, is what a secretary has.
                      </p>
                    </div>
                  </div>

                  <div class="p-2 lg:p-5 md:w-1/3">
                    <div class="grid place-items-center bg-white border border-gray-200 p-3 rounded-lg overflow-hidden text-center relative">
                      <div class="overflow-hidden object-center rounded-full w-40 h-40">
                        <img alt="content" src="./assets/Treasurer_Rekha.jpg" />
                      </div>
                      <h1 class="title-font sm:text-2xl text-xl font-medium text-gray-900 mt-2">
                        Rekha Pandey
                      </h1>
                      <h3 class="text-lg font-medium text-gray-500 mb-3">
                        Treasurer
                      </h3>
                      <p class="leading-relaxed mb-3">
                        The money you make is a symbol of the value you create.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div class="-mx-3 md:flex items-start">
              <div class="px-3 md:w-1/3">
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Nisha_Mishra.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Nisha Mishra.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      ज्ञान के दीप से अंधकार को मिटाएँगे,
                      जो अपने पथ से भटक गए
                      उन्हें मंज़िल तक पहुचाएंगे
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Amit_Hajela.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        AMIT HAJELA.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      खुशी हो चेहरे पर सबके ऐसा संकल्प अब थाना ही,
                      अंधे ऐ तूफ़ा ऐ बस उम्र बढ़ते जाना ही,
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="px-3 md:w-1/3">
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Hema_Pandey.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Hema Pandey.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      हुनर तलाशोगे तो हुनर निकल के आएगा
                      संकल्प हमारा लोगों में खुशियों की अलख जगाएगा
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>

                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Vijay_Sanwal.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Vijay Sanwal.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      संकल्प जो लिया उसे कर के हम दिखायेगें
                      हर एक फ़िक्रमंद के लबों पे मुस्कान हम सजायेगें
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="px-3 md:w-1/3">
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Bhagwati_Sanwal.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Bhagwati Sanwal.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      कुछ कर गुज़रने के लिए मौसम नहीं मन चाहिए,
                      साधन सब जुट जाएँगे बस एक संकल्प चाहिए
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Sunita_Hajela.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Dr. Suneeta Hajela.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      सेवा की शर्ते नही होती सेवा का भाव होता है, इसी भाव का संकल्प हमारी संस्था ने लिया है ||
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="-mx-3 md:flex items-start">
              <div class="px-3 md:w-1/3">
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Alka_Singh.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Alka Singh.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      समय की पुकार यही है,
                      फिर मानवता का दीप जले
                      खुशियों से महके सबका आँगन
                      जन जन में सेवा का संकल्प मिले
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Alok_Tiwari.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Dr. Alok Tiwari.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      संकल्प ये हमने ठाना है
                      सेवा का दीप जलाना है
                      आगे बढ़ते जाना है
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="px-3 md:w-1/3">
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Ajeet_Singh.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Ajeet Singh.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      अगर हो आत्मविश्वास का दृढ़ संकल्प
                      तो ज़िन्दगी में कुछ नहीं और विकल्प
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Rajesh_Nigam.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Rajesh Kumar Nigam.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      जब किसी कार्य को पूरा करने का हम संकल्प लेते हैं तो हमारे शरीर में एक तरह की सकारात्मक ऊर्जा का संचार होता है
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="px-3 md:w-1/3">
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Sudhanshu_Dey.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Sudhanshu Dey.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      दीप से दीप जलाते हैं
                      सेवा का भाव जगाते हैं
                      संकल्प ये हमने ठाना है
                      चलो कुछ करके दिखाते हैं
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
                <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div class="w-full flex mb-4 items-center">
                    <div class="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="./assets/Core_Committee/Artika_Srivastava.jpg" alt="" />
                    </div>
                    <div class="flex-grow pl-3">
                      <h6 class="font-bold text-sm uppercase text-gray-600">
                        Artika Srivastava.
                      </h6>
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-sm leading-tight">
                      <span class="text-lg leading-none italic font-bold text-gray-400 mr-1">
                        "
                      </span>
                      विकल्पों में विकल्प मिलेंगे हज़ार
                      संकल्प सेवा का बस एक आधार
                      <span class="text-lg leading-none italic font-bold text-gray-400 ml-1">
                        "
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center max-w-4xl mx-auto">
              <h1 class="text-4xl md:text-5xl font-bold m-5 text-gray-600">
                संकल्प पत्र
              </h1>
              <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="40" viewBox="0 0 356.277 480.001">
                <g id="Pen" transform="translate(-61.862)">
                  <g id="Group_25" data-name="Group 25">
                    <g id="Group_24" data-name="Group 24">
                      <path id="Path_62" data-name="Path 62" d="M417.509,131.585,399.156,82.363l6.311-8.507a10,10,0,0,0-2.073-13.989l-78.04-57.9a10,10,0,0,0-13.989,2.073L108.733,277.149,85.364,307.215A10,10,0,0,0,87.3,321.382l31.675,23.507L92.791,380.211a10,10,0,1,0,16.066,11.911l26.178-35.313,30.9,22.935a10,10,0,0,0,13.972-2.048l22.854-30.606.009-.013.01-.012L384.951,101.513l11.921,31.971L305.388,256.75a10,10,0,1,0,16.06,11.919l94.721-127.63A10,10,0,0,0,417.509,131.585ZM169.852,357.74,107.43,311.415l11.17-14.371,62.14,46.115Zm22.828-30.625-61.985-46L263.512,102.1l61.209,47.027ZM336.638,133.066,275.43,86.04l46.038-62.049,61.978,45.98Z" />
                      <path id="Path_63" data-name="Path 63" d="M363.434,411.411H71.862a10,10,0,0,0,0,20H363.434a10,10,0,0,0,0-20Z" />
                      <path id="Path_64" data-name="Path 64" d="M290.545,460H144.751a10,10,0,0,0,0,20h145.8a10,10,0,1,0,0-20Z" />
                    </g>
                  </g>
                </g>
              </svg>

              <div class="text-center m-5">
                <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
                <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
              </div>
              <p className="text-gray-600">
                "संकल्प सेवा का" एक पंजीकृत गैर सरकारी संस्था है। इस संस्था का गठन 20 दिसम्बर, 2018 को समाज में अशिक्षित को शिक्षित, अक्षम को सक्षम तथा कमजोर एवम् असहाय लोगों की सहायता करने के सेवा-भाव को ध्यान में रखते हुए किया गया है। संस्था के सदस्यों द्वारा समाज के प्रति अपने उत्तरदायित्वों को समझते हुए निरंतर सेवा का प्रयास किया जा रहा है। <br /><br />
                संस्था का मुख्य उद्देश्य समाज के उस तबके के बच्चों को शिक्षित करना है जो आर्थिक या व्यक्तिगत विवशता के चलते विद्यालय जाने एवं शिक्षा ग्रहण करने में असमर्थ हैं, साथ ही संस्था समाज के आश्रयहीन वृद्धजनों, निर्बल एवं जरूरतमदों के प्रति भी पूर्ण सहानुभूति रखते हुए दृढ़ संकल्पबद्ध है तथा यथासंभव उन्हें सहायता भी प्रदान करती है। <br />
                <div class="text-center m-5">
                  <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                  <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                  <span class="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
                  <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                  <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                </div>
                वर्तमान में संस्था द्वारा विराज खंड, गोमती नगर, लखनऊ में सिंगापुर माल के निकट मलिन बस्ती के निर्बल वर्ग के बच्चों के लिये निःशुल्क "संकल्प पाठशाला" का संचालन कर शिक्षित करने का प्रयास किया जा रहा है। इन बच्चों के लिए समस्त पाठ्य सामग्री भी संस्था द्वारा ही प्रदान की जाती है। संस्था के द्वारा शैक्षिक सत्र 2019-20 में प्राथमिक विद्यालय, रामआसरेपुरवा, गोमती नगर, लखनऊ में विभिन्न कक्षाओं में मलिन वस्ती के 10 बच्चों को प्रवेश दिलाया गया।
                संस्था द्वारा विभिन्न वृद्धाश्रमों एवं सरकारी अस्पतालों में वृद्धजनों एवं मरीजों को उनकी इच्छा एवं आवश्यकता के अनुसार वस्तुएं भी वितरित की जाती हैं। इसके अतिरिक्त ठंड के मौसम में गर्म कपड़ों एवं कंबलों का वितरण भी जरूरतमदों को संस्था द्वारा समय-समय पर किया जाता है।‍‍‌‌ <br /><br />
              </p>
              <p className="font-bold">
                संस्था अपने आगामी क्रियाकलापों में निर्बल वर्ग के लिए चिकित्सा शिविर, महिलाओं को आत्मनिर्भर बनाने के लिए सिलाई स्कूल एवम् उन्हें विभिन्न प्रकार के ऐसे उधमों के लिए प्रेरित करने, जिससे वो आगे बढ सके और अपने अंदर आत्मविश्वास को जगा सके, इसके लिये काउसिलिंग की व्यवस्था के प्रति भी संकल्पबद्ध है।
              </p>
              <div class="text-center m-5">
                <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
                <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceComponent;
