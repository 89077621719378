import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";

function Contact() {
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [mailBody, setmailBody] = useState("");
  const [subject, setSubject] = useState("");
  // const history = useHistory();
  const handleContactSubmit = (e) => {
    if (name === "" || email === "" || mailBody === "" || subject === "") {
      swal({
        title: "Please fill all the fields",
        text: "",
        icon: "warning",
        buttons: {
          confirm: { text: "Okay", className: "sweet-warning" },
        },
      })
      return;
    }
    else {
      e.preventDefault();
      axios
        .post(
          "https://sankalp-backend.herokuapp.com/api/contact",
          {
            name,
            email,
            subject,
            mailBody,
          }
        ).then((response) => {
          if (response?.status === 200) {
            swal({
              title: "Email Sent Successfully!",
              text: "",
              icon: "success",
              buttons: {
                confirm: { text: "Okay", className: "sweet-warning" },
              },
            });
            setName("");
            setemail("");
            setmailBody("");
            setSubject("");
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className="md:-mt-28 mt-0 ">
      <h1 class="text-4xl md:text-5xl text-center font-bold md:mt-20 md:pt-10  text-gray-600">
        Contact Us
      </h1>
      <div class="text-center my-5">
        <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
        <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
      </div>

      <div className="m-10 md:mx-20 md:px-20 py-5 text-black rounded-md dark:bg-gray-800 mt-5">
        <div className="w-full md:text-2xl text-md text-black text-center">
          Looking to help the needful?
          Fill up the form and one of our representatives will get in touch with you.
        </div>

        <div className="w-full my-10 grid grid-cols-1 sm:grid-cols-3">
          <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Ominvest+Business+Center/@23.5746516,58.2950687,17z/data=!3m1!4b1!4m5!3m4!1s0x3e8e016752655811:0x2b30e06b8368993f!8m2!3d23.5746467!4d58.2972574"
            className="flex flex-col items-center place-content-center px-4 py-3 text-black rounded-md dark:text-gray-200 hover:bg-gray-200 hover:text-black dark:hover:bg-blue-500">
            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" >
              <path d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                fillRule="evenodd" clipRule="evenodd" />
            </svg>
            <span className="mt-2 text-center">
              E-2/670-71 , Vinit Khand, gomti nagar, lucknow-226010
            </span>
          </a>

          <a href="tel:+986-71102818" className="flex flex-col items-center place-content-center px-4 py-3 text-black rounded-md dark:text-gray-200 hover:bg-gray-200 hover:text-black dark:hover:bg-blue-500">
            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
            <span className="mt-2">+91-94158 21934</span>
          </a>

          <a href="mailemail:ceo@alphasecs.com" className="flex flex-col items-center place-content-center px-4 py-3 text-black rounded-md dark:text-gray-200 hover:bg-gray-200 hover:text-black dark:hover:bg-blue-500">
            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            <span className="mt-2">sankalpsewaka@gmail.com</span>
          </a>
        </div>

        <div className="mt-6 ">
          {/* <form onSubmit={handleContactSubmit}> */}
          <div className="items-center -mx-2 mb-3 md:flex">
            <div className="w-full mx-2">
              <label className="block mb-2 text-sm font-medium text-black dark:text-gray-200">
                Name
              </label>
              <input
                name="name"
                id="name"
                className="block w-full px-4 py-2 text-black bg-gray-200 border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
              />
            </div>

            <div className="w-full mx-2 mt-4 md:mt-0">
              <label className="block mb-2 text-sm font-medium text-black dark:text-gray-200">
                E-mail
              </label>
              <input
                className="block w-full px-4 py-2 text-black bg-gray-200 border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                onChange={(e) => setemail(e.target.value)}
                value={email}
                id="email"
                type="email"
                name="email"
              />
            </div>
          </div>

          <div className="w-full -ml-2 mb-3 pt-2">
            <div className="w-full mx-2">
              <label className="block mb-2 text-sm font-medium text-black dark:text-gray-200">
                Subject
              </label>
              <input
                className="block w-full px-4 py-2 text-black bg-gray-200 border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
                id="subject"
                type="text"
                name="subject"
              />
            </div>
          </div>

          <div className="w-full mt-4 mb-3">
            <label className="block mb-2 text-sm font-medium text-black dark:text-gray-200">
              Message
            </label>
            <textarea onChange={(e) => setmailBody(e.target.value)} value={mailBody} name="mailBody" id="mailBody"
              className="block w-full h-40 px-4 py-2 text-black bg-gray-200 border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring">
            </textarea>
          </div>

          <div className="flex justify-center mt-6">
            <button className="px-4 py-2 font-bold text-black transition-colors duration-200 transform bg-gray-200 rounded-md hover:bg-gray-200 focus:outline-none focus:bg-gray-300"
              type="submit" onClick={handleContactSubmit}>
              Send Message
            </button>
          </div>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
}

export default Contact;
