import React from 'react'

const FooterComponent = () => {
  return (
    <div>
      <footer className="text-gray-600 body-font">
        <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
          <a
            className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
            rel="noreferrer"
          >
            <img src="./assets/Logo.png" alt="संकल्प सेवा का" width="45vh" />
            <span className="ml-3 text-xl">|| संकल्प सेवा का ||</span>
          </a>
          <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
            © 2021 संकल्प सेवा का—
            <a
              href="https://www.linkedin.com/in/sanchit10hajela/"
              className="text-gray-600 ml-1"
              rel="noopener noreferrer"
              target="_blank"
            >
              @xanxit
            </a>
            <a
              href="https://github.com/nisheet-n"
              className="text-gray-600 ml-1"
              rel="noopener noreferrer"
              target="_blank"
            >
              @nisheet-n
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default FooterComponent
