import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "./NavbarLinks";
import "./navbarstyle.css";

let isOpen = false;

const Toggle1 = (y, x) => {
  //   console.log("closed");
  y.style.display = "none";
  x[0].style.transform = "none";
  x[1].style.opacity = "100";
  x[2].style.transform = "none";
  isOpen = false;
};
const Toggle2 = (y, x) => {
  //   console.log("open");
  y.style.display = "block";
  y.style.animation = "fade-in 1s ease-in";
  x[0].style.transform = "rotate(-45deg) translate(-5px,6px)";
  x[0].style.transition = "transform 0.4s ease-in";
  x[1].style.opacity = "0";
  x[1].style.transition = "opacity 0.2s ease-in";
  x[2].style.transform = "rotate(45deg) translate(-5px,-6px)";
  x[2].style.transition = "transform 0.4s ease-in";
  isOpen = true;
};

const handleClick = () => {
  const y = document.getElementById("drop-down");
  const x = document.getElementById("nav_parent").children;
  isOpen ? Toggle1(y, x) : Toggle2(y, x);
};

export default function Navbar() {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 120) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div
      className={
        !colorChange
          ? `block text-black font-semibold lg:px-12 md:px-8 px-4 shadow-2xl lg:py-6 z-50 sticky top-0 bg-white `
          : `block text-black font-semibold lg:px-12 md:px-8 px-4 shadow-2xl lg:py-6 z-50 sticky top-0 bg-white`
      }
    // style={{ backgroundColor: "#dbffff" }}
    >
      <div className="sm:h-20 md:h-10 lg:h-9 flex justify-between justify-items-center">
        <div className="inline-flex border-b-3 border-blue-500 sm:pt-3 md:pt-3 lg:pt-0" style={{ width: "20%" }}>
          <Link to="/" onClick={scrollToTop}>
            {/* <video
                playsInline
                autoPlay
                muted
                loop
                width="140px"
                height="30px"
                className="rounded-lg"
              >
                <source src="/logo2.mp4" type="video/mp4" />
              </video> */}
            <img src="./assets/Logo.png" alt="संकल्प सेवा का" width="55vh" />
          </Link>
        </div>

        <div className="hidden sm:flex text-md sm:pt-6 md:pt-6 lg:pt-2" id="">
          <NavLinks />
        </div>

        <div
          className="sm:hidden py-4 cursor-pointer"
          id="nav_parent"
          onClick={handleClick}
        >
          <div id="div1" className="h-1 w-6 m-1 bg-red-500"></div>
          <div id="div2" className="h-1 w-6 m-1 bg-red-500"></div>
          <div id="div3" className="h-1 w-6 m-1 bg-red-500"></div>
        </div>
      </div>
      <div className="hidden sm:hidden text-black text-sm py-3" id="drop-down">
        <NavLinks colorChange={colorChange} />
      </div>

      {/* <div className="z-50 p-6 bg-green-300 left-4 top-0">
      Concrete
      <br />
      Industrial Inc.
    </div> */}
    </div >
  );
}
