import React from "react";

const AboutusComponent = () => {
  return (
    <div>
      <h1 class="text-5xl md:text-6xl text-center font-bold pt-12 text-gray-600">
        Our Works
      </h1>
      <div class="text-center mb-20">
        <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
        <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
      </div>
      <div className="h-full">
        <div className="container mx-auto  lg:px-20">
          <div className="grid grid-cols-3 h-full pb-40">
            <div className="border-r border-gray-300 mx-3 lg:pl-20">
              <div className=" py-10 pb-3 mt-72 h-4/6 relative bg-purple-100 group hover:bg-purple-200 cursor-pointer transition ease-out duration-300">
                <div>
                  <div className="w-4 h-1/5 absolute right-0 -top-48 bg-purple-100 group-hover:bg-purple-50"></div>
                  <img
                    src="/assets/hang1.jpeg"
                    alt="https://www.pngegg.com/en/png-nllal/download"
                    className="w-full h-full text-center"
                  />
                </div>
              </div>
            </div>
            <div className="border-r border-gray-300 mx-3 lg:pl-20">
              <div className=" py-10  pb-3 mt-32 h-4/6 relative bg-indigo-100 group hover:bg-indigo-200 cursor-pointer transition ease-out duration-300">
                <div>
                  <div className="w-4 h-1/5 	absolute right-0 -top-48 bg-indigo-100  group-hover:bg-indigo-50"></div>
                  <img
                    src="/assets/hang2.jpeg"
                    alt="https://www.pngegg.com/en/png-zquqj/download"
                  />
                </div>
              </div>
            </div>
            <div className="border-r border-gray-300 mx-3 lg:pl-20">
              <div className=" py-10 pb-3 mt-5 h-4/6 relative bg-red-100 group hover:bg-red-200 cursor-pointer transition ease-out duration-300">
                <div>
                  <div className="w-4 h-1/5 absolute right-0 -bottom-44 bg-red-100 group-hover:"></div>
                  <img
                    src="/assets/hang3.jpeg"
                    alt="https://www.pngegg.com/en/png-epwii/download"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 class="text-4xl md:text-5xl text-center font-bold text-gray-600">
        Our Missions
      </h1>
      <div class="text-center mb-5 lg:mb-0">
        <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-10 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
        <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-10 h-1 rounded-full bg-indigo-500 ml-1"></span>
      </div>

      <div className="bg-white lg:py-12 lg:flex lg:justify-start">
        <div className="bg-gray-200 lg:bg-white p-5 lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg lg:rounded-lg">
          <div className="lg:w-1/2">
            <div
              className="h-64 bg-cover lg:rounded-lg lg:h-80"
              style={{
                backgroundImage: `url('/assets/mission1.jpg')`,
              }}
            ></div>
          </div>
          <div className="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
            <h2 className="text-3xl text-gray-800 font-bold">
              <span className="text-indigo-600">Stationary Distribution </span>with Canara Bank
            </h2>
            <p className="mt-4 text-gray-600">
              Our children of Sankalp Pathshala were invited in Canara Bank Circle office gomti nagar Lucknow on occasion of Corporate Social Responsibility for Community. Bank distributed stationary with school bag & Refreshments for Children. Guest of Honour  former Cricket Player Mr. Sunil joshi ,CGM Of Canara bank Mr.Umesh kumar Sharma & all Canara Bank officer's Assosiation member's were present there.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white lg:p-12 lg:flex lg:justify-end">
        <div className="bg-white p-5 lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg lg:rounded-lg">
          <div className="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
            <h2 className="text-3xl text-gray-800 font-bold">
              Helping <span className="text-indigo-600">Aisha for her Education</span>
            </h2>
            <p className="mt-4 text-gray-600">
              This child Aisha, who was unable to continue her further education due to the sudden demise of her parents and on being informed by the school, the NGO took the responsibility of further education and fees of this girl child and this The girl child also made this effort successful by bringing very good marks in her school!
            </p>
          </div>
          <div className="lg:w-1/2">
            <div
              className="h-64 bg-cover lg:rounded-lg lg:h-80"
              style={{
                backgroundImage: `url('/assets/mission2.jpg')`,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="pt-5 bg-white lg:py-12 lg:flex lg:justify-start">
        <div className="bg-gray-200 lg:bg-white p-5 lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg lg:rounded-lg">
          <div className="lg:w-1/2">
            <div
              className="h-64 bg-cover lg:rounded-lg lg:h-80"
              style={{
                backgroundImage: `url('/assets/mission3.jpg')`,
              }}
            ></div>
          </div>
          <div className="py-5 px-6 lg:py-12 lg:px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
            <h2 className="text-3xl text-gray-800 font-bold">
              Helping <span className="text-indigo-600">Single Parents</span>
            </h2>
            <p className="mt-4 text-gray-600">
              Our organization expresses a lot of gratitude to Mr. Atirish Tiwari sir, through whose contribution amount of Rs. 10000/- helped a woman who is a single parent of two little girls, 3 years and 6 months, and the accidental death of her husband. Struggling with her financial condition due to her death, on coming to the notice of the organization that this woman wants to stand on her feet by sewing, this sewing machine was provided with the help of Shri Atirish Tiwari ji, so that she can stand on her feet.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white lg:p-12 lg:flex lg:justify-end">
        <div className="bg-white p-5 lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg lg:rounded-lg">
          <div className="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
            <h2 className="text-3xl text-gray-800 font-bold">
              <span className="text-indigo-600">Sankalp </span>Pathshala
            </h2>
            <p className="mt-4 text-gray-600">
              As a result of making the children studying in Sankalp Pathshala and their parents aware of the necessity of continuous education, today the children who are studying in the Sankalp school for whom the school was a dream, today the members of the institution and the school Due to the tireless efforts of the teachers, our 16 children have been admitted to the government school.
            </p>
          </div>
          <div className="lg:w-1/2">
            <div
              className="h-64 bg-cover lg:rounded-lg lg:h-80"
              style={{
                backgroundImage: `url('/assets/mission4.jpg')`,
              }}
            ></div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-5 mb-10 md:mb-20 px-6">
        <div
          className="h-64 rounded-md overflow-hidden bg-cover bg-center"
          style={{
            backgroundImage: `url('/assets/card1.jpeg')`,
          }}
        >
          <div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
            <div className="px-10 max-w-xl">
              <h2 className="text-2xl text-white font-semibold">Impart Education</h2>
              <p className="mt-2 text-white">
                " Education is for improving the lives of others and for leaving
                your community and world better than you found it. One of the aims of the organisation
                is to educate the unprivileged children."
              </p>
            </div>
          </div>
        </div>
        <div className="md:flex mt-8 md:-mx-4">
          <div
            className="w-full h-64 md:mx-4 rounded-md overflow-hidden bg-cover bg-center md:w-1/2"
            style={{
              backgroundImage: `url('/assets/card2.jpeg')`,
            }}
          >
            <div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
              <div className="px-10 max-w-xl">
                <h2 className="text-2xl text-white font-semibold">Welfare of Senior Citizen</h2>
                <p className="mt-2 text-white">
                  "Old age is not a disease - it is strength and survivorship, triumph over all kinds of vicissitudes and disappointments, trials, and illnesses."
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-full h-64 mt-8 md:mx-4 rounded-md overflow-hidden bg-cover bg-center md:mt-0 md:w-1/2"
            style={{
              backgroundImage: `url('/assets/card3.jpeg')`,
            }}
          >
            <div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
              <div className="px-10 py-10 max-w-xl">
                <h2 className="text-2xl text-white font-semibold">
                  Women Empowerment
                </h2>
                <p className="mt-2 text-white">
                  "To all the little girls who are watching this, never doubt that you are valuable and powerful, and deserving of every chance and opportunity in the world to pursue and achieve your own dreams."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 class="text-4xl md:text-5xl text-center font-bold pt-2 mt-10 text-gray-600">
        Gallery
      </h1>
      <div class="text-center mb-10">
        <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-32 h-1 rounded-full bg-indigo-500"></span>
        <span class="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
        <span class="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
      </div>

      <div className="flex flex-wrap">
        <div className="flex flex-wrap w-1/2">
          <div className="md:p-2 p-1 w-1/2">
            <img
              alt="gallery"
              className="w-full h-full object-cover object-center block"
              src="/assets/gallery1.jpeg"
            />
          </div>
          <div className="md:p-2 p-1 w-1/2">
            <img
              alt="gallery"
              className="w-full object-cover h-full object-center block"
              src="/assets/gallery2.jpeg"
            />
          </div>
          <div className="md:p-2 p-1 w-full">
            <img
              alt="gallery"
              className="w-full h-full object-cover object-center block"
              src="/assets/gallery3.jpeg"
            />
          </div>
        </div>
        <div className="flex flex-wrap w-1/2">
          <div className="md:p-2 p-1 w-full">
            <img
              alt="gallery"
              className="w-full h-auto object-cover object-center block"
              src="/assets/gallery4.jpeg"
            />
          </div>
          <div className="md:p-2 p-1 w-1/2">
            <img
              alt="gallery"
              className="w-full h-full object-cover object-center block"
              src="/assets/gallery5.jpeg"
            />
          </div>
          <div className="md:p-2 p-1 w-1/2">
            <img
              alt="gallery"
              className="w-full object-cover h-auto object-center block"
              src="/assets/gallery6.jpeg"
            />
          </div>
        </div>
      </div>

      <div class="text-center">
        <a href="https://drive.google.com/drive/u/1/folders/1-1tLx3jF1k1oJ-NmzFsKd-mm2JBscmfJ" target="_blank" class="inline-flex text-gray-600 hover:text-white font-medium bg-white hover:bg-indigo-600 border-2 border-gray-300 hover:border-white py-3 px-5 cursor-pointer items-center rounded-lg">
        View More
          <svg class="ml-5" id="photos-gallery-images-media-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
            <rect id="Rectangle_9" data-name="Rectangle 9" width="60" height="60" rx="10" fill="#fff" />
            <path id="Path_99" data-name="Path 99" d="M24,22V15.515l-1.071-1.071a6,6,0,0,0-8.485,0h0a6,6,0,0,0,0,8.485L20.1,28.586a6,6,0,0,0,8.485,0h0a6.142,6.142,0,0,0,.537-.657A6,6,0,0,1,24,22Z" fill="#ffe1a0" />
            <path id="Path_100" data-name="Path 100" d="M24.343,31.839a7.475,7.475,0,0,1-5.3-2.193l-5.657-5.657a7.5,7.5,0,1,1,10.6-10.607l1.071,1.072a1.5,1.5,0,0,1,.439,1.061V22a4.519,4.519,0,0,0,3.841,4.445,1.5,1.5,0,0,1,.981,2.386,7.285,7.285,0,0,1-.675.815,7.479,7.479,0,0,1-5.3,2.193ZM18.687,14.188a4.5,4.5,0,0,0-3.183,7.68l5.657,5.657a4.512,4.512,0,0,0,5.029.922A7.54,7.54,0,0,1,22.5,22V16.136l-.632-.632a4.485,4.485,0,0,0-3.181-1.316Z" fill="#f29580" />
            <path id="Path_101" data-name="Path 101" d="M45.556,37.071,39.9,31.414a6,6,0,0,0-8.485,0h0a6.142,6.142,0,0,0-.537.657A6,6,0,0,1,36,38v6.485l1.071,1.071a6,6,0,1,0,8.485-8.485Z" fill="#c1f7fd" />
            <path id="Path_102" data-name="Path 102" d="M41.313,48.813a7.451,7.451,0,0,1-5.3-2.2l-1.072-1.071a1.5,1.5,0,0,1-.439-1.061V38a4.519,4.519,0,0,0-3.841-4.445,1.5,1.5,0,0,1-.981-2.386,7.285,7.285,0,0,1,.675-.815,7.509,7.509,0,0,1,10.607,0l5.657,5.657a7.5,7.5,0,0,1-5.3,12.8ZM37.5,43.864l.632.631A4.5,4.5,0,0,0,44.5,38.132l-5.657-5.657a4.507,4.507,0,0,0-5.029-.922A7.54,7.54,0,0,1,37.5,38Z" fill="#7bcdd1" />
            <path id="Path_103" data-name="Path 103" d="M28.586,31.414h0a6.141,6.141,0,0,0-.657-.537A6,6,0,0,1,22,36H15.515l-1.071,1.071a6,6,0,1,0,8.485,8.485L28.586,39.9a6,6,0,0,0,0-8.486Z" fill="#f2bdd6" />
            <path id="Path_104" data-name="Path 104" d="M18.687,48.813a7.5,7.5,0,0,1-5.3-12.8l1.071-1.072a1.5,1.5,0,0,1,1.061-.439H22a4.517,4.517,0,0,0,4.444-3.841,1.5,1.5,0,0,1,2.386-.98,7.193,7.193,0,0,1,.815.672h0a7.508,7.508,0,0,1,0,10.606l-5.657,5.657a7.451,7.451,0,0,1-5.3,2.2ZM16.136,37.5l-.632.632A4.5,4.5,0,1,0,21.868,44.5l5.657-5.657a4.509,4.509,0,0,0,.922-5.029A7.54,7.54,0,0,1,22,37.5Z" fill="#bf95bc" />
            <path id="Path_105" data-name="Path 105" d="M38,24h6.485l1.071-1.071a6,6,0,0,0,0-8.485h0a6,6,0,0,0-8.485,0L31.414,20.1a6,6,0,0,0,0,8.486h0a6.013,6.013,0,0,0,.657.536A6,6,0,0,1,38,24Z" fill="#f1f3f4" />
            <path id="Path_106" data-name="Path 106" d="M32.071,30.623a1.5,1.5,0,0,1-.9-.3,7.193,7.193,0,0,1-.815-.672,7.511,7.511,0,0,1,0-10.609l5.657-5.657a7.5,7.5,0,1,1,10.6,10.6l-1.071,1.072a1.5,1.5,0,0,1-1.061.439H38a4.517,4.517,0,0,0-4.444,3.841A1.5,1.5,0,0,1,32.071,30.623Zm9.242-16.435A4.485,4.485,0,0,0,38.132,15.5l-5.657,5.657a4.509,4.509,0,0,0-.922,5.029A7.54,7.54,0,0,1,38,22.5h5.864l.632-.632a4.5,4.5,0,0,0-3.183-7.68Z" fill="#f2bf80" />
            <path id="Path_107" data-name="Path 107" d="M31.414,20.1,36,15.515V14a6,6,0,0,0-12,0v8a5.959,5.959,0,0,0,6.877,5.929,5.985,5.985,0,0,1,.537-7.829Z" fill="#eff28d" />
            <path id="Path_108" data-name="Path 108" d="M30,29.5A7.508,7.508,0,0,1,22.5,22V14a7.5,7.5,0,0,1,15,0v1.515a1.5,1.5,0,0,1-.439,1.06l-4.586,4.586a4.508,4.508,0,0,0-.4,5.867,1.5,1.5,0,0,1-.982,2.385A7.485,7.485,0,0,1,30,29.5Zm0-20A4.5,4.5,0,0,0,25.5,14v8a4.507,4.507,0,0,0,2.921,4.214,7.506,7.506,0,0,1,1.933-7.174L34.5,14.894V14A4.505,4.505,0,0,0,30,9.5Z" fill="#f2bf80" />
            <path id="Path_109" data-name="Path 109" d="M30,32a6.033,6.033,0,0,0-.877.071,5.983,5.983,0,0,1-.537,7.828L24,44.485V46a6,6,0,0,0,12,0V38A6,6,0,0,0,30,32Z" fill="#bec6f4" />
            <path id="Path_110" data-name="Path 110" d="M30,53.5A7.508,7.508,0,0,1,22.5,46V44.485a1.5,1.5,0,0,1,.439-1.06l4.586-4.586a4.508,4.508,0,0,0,.4-5.867,1.5,1.5,0,0,1,.982-2.385A7.458,7.458,0,0,1,37.5,38v8A7.508,7.508,0,0,1,30,53.5Zm-4.5-8.394V46a4.5,4.5,0,0,0,9,0V38a4.507,4.507,0,0,0-2.921-4.214,7.506,7.506,0,0,1-1.933,7.174Z" fill="#8d9cf4" />
            <path id="Path_111" data-name="Path 111" d="M20.1,28.586,15.515,24H14a6,6,0,0,0,0,12h8a5.96,5.96,0,0,0,5.929-6.878A5.982,5.982,0,0,1,20.1,28.586Z" fill="#ffafc5" />
            <path id="Path_112" data-name="Path 112" d="M22,37.5H14a7.5,7.5,0,1,1,0-15h1.515a1.5,1.5,0,0,1,1.06.439l4.586,4.586a4.508,4.508,0,0,0,5.867.4,1.5,1.5,0,0,1,2.385.982A7.372,7.372,0,0,1,29.5,30,7.508,7.508,0,0,1,22,37.5Zm-8-12a4.5,4.5,0,0,0,0,9h8a4.507,4.507,0,0,0,4.214-2.921,7.506,7.506,0,0,1-7.174-1.933L14.894,25.5Z" fill="#f28080" />
            <path id="Path_113" data-name="Path 113" d="M46,24H38a5.959,5.959,0,0,0-5.929,6.877,5.983,5.983,0,0,1,7.828.537L44.485,36H46a6,6,0,0,0,0-12Z" fill="#c1f7fd" />
            <path id="Path_114" data-name="Path 114" d="M46,37.5H44.485a1.5,1.5,0,0,1-1.06-.439l-4.586-4.586a4.5,4.5,0,0,0-5.866-.4,1.5,1.5,0,0,1-2.386-.982A7.372,7.372,0,0,1,30.5,30,7.508,7.508,0,0,1,38,22.5h8a7.5,7.5,0,0,1,0,15Zm-.894-3H46a4.5,4.5,0,0,0,0-9H38a4.507,4.507,0,0,0-4.214,2.921,7.511,7.511,0,0,1,7.174,1.933Z" fill="#bbd9c8" />
            <path id="Path_115" data-name="Path 115" d="M24,23.5A1.5,1.5,0,0,1,22.5,22V14A7.508,7.508,0,0,1,30,6.5a1.5,1.5,0,0,1,0,3A4.5,4.5,0,0,0,25.5,14v8A1.5,1.5,0,0,1,24,23.5Z" fill="#f2c4bb" />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default AboutusComponent;
