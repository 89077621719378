import React from "react";
import swal from "sweetalert";

const HomeComponent = () => {
  return (
    <div>
      <section className="relative bg-white">
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 h-2/3 w-full md:h-full bg-center bg-cover"
            style={{
              backgroundImage: `url('/assets/home.png')`,
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <h2 className="text-white text-center text-3xl font-semibold md:text-5xl">
                  || संकल्प सेवा का ||
                  <br />
                  <span className="text-xl">Welfare and Educational Organisation</span>
                </h2>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0px)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              {/* <polygon className="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon> */}
            </svg>
          </div>
        </div>
        <section className="pb-10 bg-white -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Impart Education</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      One of the aims of the organisation is to educate the unprivileged children.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Welfare of Senior Citizen</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      We also aim to promote the welfare of needful old aged people.
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Women Empowerment</h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Promoting the independence and self sufficiency of unprivileged women.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container p-10 mb-10 mx-auto flex flex-wrap">
          <div className="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h2 className="title-font font-medium text-3xl mb-2 text-blue-700">
                ||  सेवा ही संकल्प है ||
              </h2>
              <br />
              <div className="leading-relaxed text-lg">
                संस्था के सदस्यों द्वारा समाज के प्रति अपने उत्तरदायित्व को समझते हुए निरंतर सेवा का प्रयास किया जा रहा है !!
              </div>
            </div>
            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
              <h2 className="title-font font-medium text-3xl text-gray-900">
                50+
              </h2>
              <p className="leading-relaxed">Programs</p>
            </div>
            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
              <h2 className="title-font font-medium text-3xl text-gray-900">
                1000+
              </h2>
              <p className="leading-relaxed">Needful helped</p>
            </div>
            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
              <h2 className="title-font font-medium text-3xl text-gray-900">
                30+
              </h2>
              <p className="leading-relaxed">Active Members</p>
            </div>
            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
              <h2 className="title-font font-medium text-3xl text-gray-900">
                1
              </h2>
              <p className="leading-relaxed">Vision</p>
            </div>
          </div>
          <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
            <img
              className="object-cover object-center w-full h-full"
              src="/assets/mission1.jpg"
              alt="stats"
            />
          </div>
        </div>
      </section>
      <div className="lg:grid lg:grid-cols-2 p-4">
        <section className="text-gray-600 body-font md:mx-10">
          <div className="container mx-auto flex flex-wrap">
            <h2 className="text-2xl md:text-3xl text-red-500 font-bold mb-12 text-center w-full">
              Our Work | Our Pride !!
            </h2>
            <div className="flex relative pb-8">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-600 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-400 inline-flex items-center justify-center text-white relative z-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="p-2.5"
                  viewBox="0 0 27 27.01"
                >
                  <path
                    d="M18.049,14.747A3.488,3.488,0,1,1,14.956,10.9,3.487,3.487,0,0,1,18.049,14.747Zm-4.671,12.8A13.5,13.5,0,1,1,27.559,13.675,13.495,13.495,0,0,1,13.378,27.546Zm9.793-9.8C24.69,11.323,14.434,6.538,10.258,5,7.128,8.062,6.5,15.264,8.418,23.746,12.164,24.606,17.624,22.309,23.171,17.747Z"
                    transform="translate(-0.564 -0.554)"
                  />
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-bold title-font text-xl md:text-2xl text-gray-600 mb-1 tracking-wider">
                  स्वच्छ भारत अभियान
                </h2>
                <p className="text-sm md:text-lg mb-2">
                  स्वच्छ भारत का उद्देश्य व्यक्ति, क्लस्टर और सामुदायिक शौचालयों के निर्माण के माध्यम से खुले में शौच की समस्या को कम करना या समाप्त करना है ||
                </p>
              </div>
            </div>
            <div className="flex relative pb-8">
              <div className="h-full w-10 absolute inset-0 flex flex-col items-center justify-center">
                <div className="h-full w-1 bg-gray-600 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-400 inline-flex items-center justify-center text-white relative z-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="p-2.5"
                  viewBox="0 0 27 27.01"
                >
                  <path
                    d="M18.049,14.747A3.488,3.488,0,1,1,14.956,10.9,3.487,3.487,0,0,1,18.049,14.747Zm-4.671,12.8A13.5,13.5,0,1,1,27.559,13.675,13.495,13.495,0,0,1,13.378,27.546Zm9.793-9.8C24.69,11.323,14.434,6.538,10.258,5,7.128,8.062,6.5,15.264,8.418,23.746,12.164,24.606,17.624,22.309,23.171,17.747Z"
                    transform="translate(-0.564 -0.554)"
                  />
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-bold title-font text-xl md:text-2xl text-gray-600 mb-1 tracking-wider">
                  कुछ पल बुज़ुर्गो के साथ
                </h2>
                <p className="text-sm md:text-lg mb-2">
                  कुछ पल बैठा करो बुजुर्गो के पास, हर चीज़ गूगल पर नहीं मिलती ||
                </p>
              </div>
            </div>
            <div className="flex relative pb-8">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-600 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-400 inline-flex items-center justify-center text-white relative z-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="p-2.5"
                  viewBox="0 0 27 27.01"
                >
                  <path
                    d="M18.049,14.747A3.488,3.488,0,1,1,14.956,10.9,3.487,3.487,0,0,1,18.049,14.747Zm-4.671,12.8A13.5,13.5,0,1,1,27.559,13.675,13.495,13.495,0,0,1,13.378,27.546Zm9.793-9.8C24.69,11.323,14.434,6.538,10.258,5,7.128,8.062,6.5,15.264,8.418,23.746,12.164,24.606,17.624,22.309,23.171,17.747Z"
                    transform="translate(-0.564 -0.554)"
                  />
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-bold title-font text-xl md:text-2xl text-gray-600 mb-1 tracking-wider">
                  स्वस्थ मन, स्वस्थ मस्तिष्क
                </h2>
                <p className="text-sm md:text-lg mb-2">
                  स्वस्थ शरीर में ही स्वस्थ मस्तिष्क निवास करता है ||
                </p>
              </div>
            </div>
            <div className="flex relative pb-8">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-600 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-400 inline-flex items-center justify-center text-white relative z-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="p-2.5"
                  viewBox="0 0 27 27.01"
                >
                  <path
                    d="M18.049,14.747A3.488,3.488,0,1,1,14.956,10.9,3.487,3.487,0,0,1,18.049,14.747Zm-4.671,12.8A13.5,13.5,0,1,1,27.559,13.675,13.495,13.495,0,0,1,13.378,27.546Zm9.793-9.8C24.69,11.323,14.434,6.538,10.258,5,7.128,8.062,6.5,15.264,8.418,23.746,12.164,24.606,17.624,22.309,23.171,17.747Z"
                    transform="translate(-0.564 -0.554)"
                  />
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-bold title-font text-xl md:text-2xl text-gray-600 mb-1 tracking-wider">
                  कुछ पल मरीजों के साथ
                </h2>
                <p className="text-sm md:text-lg mb-2">
                  मरीज़ो को हौसला और उम्मीद बहुत मदद करती है ||
                </p>
              </div>
            </div>
            <div className="flex relative pb-8">
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-400 inline-flex items-center justify-center text-white relative z-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="p-2.5"
                  viewBox="0 0 27 27.01"
                >
                  <path
                    d="M18.049,14.747A3.488,3.488,0,1,1,14.956,10.9,3.487,3.487,0,0,1,18.049,14.747Zm-4.671,12.8A13.5,13.5,0,1,1,27.559,13.675,13.495,13.495,0,0,1,13.378,27.546Zm9.793-9.8C24.69,11.323,14.434,6.538,10.258,5,7.128,8.062,6.5,15.264,8.418,23.746,12.164,24.606,17.624,22.309,23.171,17.747Z"
                    transform="translate(-0.564 -0.554)"
                  />
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-bold title-font text-xl md:text-2xl text-gray-600 mb-1 tracking-wider">
                  नन्हे मुन्नो के बड़े हौसले
                </h2>
                <p className="text-sm md:text-lg mb-2">
                  बच्चों का रचनात्मक मष्तिष्क बहुत कुछ कर सकता है ||
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="flex flex-col justify-center">
          <img
            src="/assets/collage.jpeg"
            className="pb-8 self-center"
            alt="timeline"
            height="500px"
            width="700px"
          />
        </div>
      </div>

      <div class="px-8 lg:px-10 text-gray-600 bg-gray-100 flex flex-wrap">
        <h2 class="text-3xl text-gray-800 font-bold md:pl-5 md:mt-10 md:w-2/5 lg:w-1/5">
          <span className="text-indigo-600">Donate </span><br />
          To a Noble Cause
        </h2>
        <div class="md:w-3/5 lg:w-3/5 md:pl-5 pt-5 lg:pt-10">
          <p class="leading-relaxed text-base">
            The value of life is not in its duration, but in its donation. You are not important because of how long you live, you are important because of how effective you live. <br />
            It doesn't matter much whether you contribute publicly or in a private way - either way is good. What matters is your true intention.
          </p>
          <div class="flex md:mt-4 mt-6">
            <button onClick={() => {
              (function () {
                const wrapper = document.createElement('div');
                wrapper.innerHTML = "<ul class='bank-list'><li>Name: Sankalp Sewa Ka</li><li>Account Number: 60322234528</li><li>Bank Name: Bank of Maharashtra</li><li>IFSC Code: MAHB0001237</li><li>Branch Name: Vinit khand Gomti Nagar Lucknow</li><li>Paytm Number: 9451084040</li></ul>";
                swal({
                  title: "Bank Details",
                  content: wrapper,
                  // icon: "./assets/QR_CODE.jpeg"
                });
              })();
            }}
              class="inline-flex text-white bg-indigo-600 border-white border-2 hover:border-gray-600 py-3 px-4 focus:outline-none rounded">
              Donate Now!
            </button>
          </div>
        </div>
        <div class="w-full lg:w-1/5 p-3">
          <div class="grid place-items-center">
            <img alt="content" class="lg:h-full lg:w-full w-2/6" src="./assets/QR_CODE.jpg" />
            <br/>
            <b>Scan and Pay via UPI</b>
          </div>
        </div>
      </div>

      <section class="text-gray-600 body-font bg-gray-100">
        <div class="container flex flex-wrap px-10 py-5 mx-auto items-center">
          <div class="md:w-1/2 md:p-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-200">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
              To Know More...
            </h1>
            <p class="leading-relaxed text-base">
              Click on the following links to know more exciting stuff about us and what we do!
              <br />
              If you love what we do, follow us to stay updated.
            </p>
          </div>

          <div class="flex flex-col md:w-1/2 md:pl-10">
            <nav class="flex flex-wrap list-none -mb-1">

              <li class="mb-1 lg:w-1/2">
                <div class="flex mt-2 md:mt-4 mr-2 md:mr-0">
                  <a href="./assets/Annual_Day_Presentation.pdf" target="_blank" class="inline-flex text-gray-600 hover:text-white font-medium bg-white hover:bg-indigo-600 border-2 border-gray-300 hover:border-white py-3 px-5 cursor-pointer items-center rounded-lg">
                    Annual Day
                    <svg class="ml-5" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 309.267 309.537">
                      <g id="pdf" transform="translate(-0.135)">
                        <path id="Path_67" data-name="Path 67" d="M270.744.271V0S113.917.193,58.171.251A18.953,18.953,0,0,0,38.793,19.629v289.9H251.414A19.336,19.336,0,0,0,270.743,290.2V19.59h19.329V.261Z" fill="#e2574c" />
                        <path id="Path_68" data-name="Path 68" d="M232.086,290.208a19.336,19.336,0,0,1,19.329-19.329H19.464a19.329,19.329,0,0,0,0,38.658h231.95A19.335,19.335,0,0,1,232.086,290.208Z" fill="#b53629" />
                        <path id="Path_69" data-name="Path 69" d="M290.073.271A19.336,19.336,0,0,0,270.744,19.6V38.929h19.329a19.329,19.329,0,1,0,0-38.658Z" fill="#b53629" />
                        <path id="Path_70" data-name="Path 70" d="M217.569,137.15c3.247,0,4.832-2.832,4.832-5.567,0-2.832-1.662-5.567-4.832-5.567h-18.44c-3.586,0-5.6,2.986-5.6,6.282v45.317c0,4.04,2.3,6.282,5.4,6.282s5.4-2.242,5.4-6.282V165.177h11.153c3.47,0,5.19-2.832,5.19-5.644,0-2.754-1.72-5.49-5.19-5.49H204.329V137.15Zm-62.327-11.133H141.75a5.966,5.966,0,0,0-6.263,6.243v45.4c0,4.629,3.74,6.079,6.437,6.079h14.139c16.758,0,27.824-11.027,27.824-28.047C183.879,137.7,173.479,126.017,155.242,126.017Zm.658,46.525h-8.234V137.208h7.422c11.211,0,16.1,7.529,16.1,17.918C171.189,164.839,166.4,172.542,155.9,172.542Zm-49.435-46.525H93.1c-3.779,0-5.866,2.493-5.866,6.282v45.317c0,4.04,2.4,6.282,5.644,6.282s5.683-2.242,5.683-6.282V164.4h8.36c10.341,0,18.875-7.326,18.875-19.107C125.794,133.758,117.56,126.017,106.465,126.017Zm-.222,27.747H98.56V136.658h7.683c4.755,0,7.78,3.7,7.78,8.553C114.013,150.043,111,153.764,106.243,153.764Z" fill="#fff" />
                      </g>
                    </svg>
                  </a>
                </div>
              </li>

              <li class="mb-1 lg:w-1/2">
                <div class="flex mt-2 md:mt-4 ml-2 md:ml-0">
                  <a href="https://www.facebook.com/%E0%A4%B8%E0%A4%82%E0%A4%95%E0%A4%B2%E0%A5%8D%E0%A4%AA-%E0%A4%B8%E0%A5%87%E0%A4%B5%E0%A4%BE-%E0%A4%95%E0%A4%BE-2181210535529173/" target="_blank" class="inline-flex text-gray-600 hover:text-white font-medium bg-white hover:bg-indigo-600 border-2 border-gray-300 hover:border-white py-3 px-5 cursor-pointer items-center rounded-lg">
                    Facebook
                    <svg class="ml-5" id="facebook" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 291.319 291.319">
                      <path id="Path_65" data-name="Path 65" d="M145.659,0A145.66,145.66,0,1,1,0,145.66,145.66,145.66,0,0,1,145.659,0Z" fill="#3b5998" />
                      <path id="Path_66" data-name="Path 66" d="M163.394,100.277h18.772V72.547H160.1v.1c-26.738.947-32.218,15.977-32.7,31.763h-.055v13.847H109.136v27.156h18.207v72.793h27.439V145.413h22.477l4.342-27.156h-26.81v-8.366C154.791,104.556,158.341,100.277,163.394,100.277Z" fill="#fff" />
                    </svg>
                  </a>
                </div>
              </li>
            </nav>
          </div>
        </div>
      </section>

      <div className="md:py-5 md:flex md:justify-center">
        <div className="p-5 md:mx-8 md:flex md:max-w-5xl md:shadow-lg md:rounded-lg md:border-gray-100 md:border-2">
          <div className="md:w-1/2">
            <div className="h-64 bg-cover lg:h-80"
              style={{
                backgroundImage: `url('/assets/home.png')`,
              }}>
            </div>
          </div>

          <div className="p-8 max-w-xl md:max-w-5xl md:w-1/2">
            <h2 className="text-3xl text-gray-800 font-bold">
              The <span className="text-indigo-600">Sankalp of Sewa</span>
            </h2>
            <p className="mt-4 text-black text-lg">
              A Sankalp for our services to the society to provide education to the underprivileged children, woman empowerment, and a helping hand to the needy people.
              We are doing our services for 3 years in the above-mentioned fields. <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
